var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "kycVerifyModal",
    attrs: {
      "centered": "",
      "size": _vm.showTips ? 'md' : 'xl',
      "hide-footer": true,
      "dialog-class": "kycVerifyModal-dialog",
      "header-class": "kycVerifyModal-header",
      "data-testid": "eKYC-header",
      "body-class": "kycVerifyModal-body"
    },
    on: {
      "hide": _vm.kycVerifyModalHidden
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function () {
        return [_c('h2', {
          staticClass: "package-kycVerifyModal-title"
        }, [_vm._v("\n      " + _vm._s(_vm.$t(_vm.kycVerifyModalTitle)) + "\n    ")])];
      },
      proxy: true
    }, {
      key: "modal-header-close",
      fn: function () {
        return [_c('i', {
          staticClass: "airalo-icon-close-round-filled tw-block tw-text-5.5 tw-mt-2.5 tw-mr-2.5"
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.kycVerifyModalData.isShow,
      callback: function ($$v) {
        _vm.$set(_vm.kycVerifyModalData, "isShow", $$v);
      },
      expression: "kycVerifyModalData.isShow"
    }
  }, [_vm._v(" "), _vm._v(" "), _c('div', {
    staticClass: "c--shared_kyc-verify-modal"
  }, [_vm.showTips ? _c('div', {
    staticClass: "kycVerifyModal-content",
    attrs: {
      "data-testid": "eKYC-content"
    }
  }, [_vm.showDestinationAddress ? [_c('DestinationAddress', {
    on: {
      "click": _vm.submitKyc
    }
  })] : [_c('VerificationTips', {
    attrs: {
      "privacy-info": _vm.privacyInfo,
      "is-destination-address-required": _vm.kycVerifyModalData.isDestinationAddressRequired
    },
    on: {
      "click": _vm.checkDestinationAddressOrSubmitKyc
    }
  })]], 2) : [_c('b-embed', {
    attrs: {
      "type": "iframe",
      "src": _vm.modalSrc,
      "allow": "camera;fullscreen;accelerometer;gyroscope;magnetometer",
      "allowfullscreen": ""
    }
  })]], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }