<template>
  <div class="tw-flex tw-flex-col tw-gap-6">
    <div v-html="content.subtitle" class="tips-description tp-6" />
    <div v-html="content.body" class="tips-description tp-6" />

    <Privacy :privacy-info="privacyInfo" />

    <button
      class="kycVerifyModal-continueBtn call-to-action dark d-block btn-primary-hv"
      data-testid="eKYC-continue-button"
      @click="$emit('click')"
    >
      {{ $t('global.start-ekyc-verification') }}
    </button>
  </div>
</template>

<script>
  import Privacy from '@/components/shared/kyc-verify-modal/verification-tips/privacy.vue'

  export default {
    name: 'verification-tips',
    components: {
      Privacy,
    },
    props: {
      isDestinationAddressRequired: {
        type: Boolean,
        default: false,
      },
      privacyInfo: {
        type: Object,
        required: true,
      },
    },
    computed: {
      content() {
        let subtitle = 'scenes.ekyc.tips.subtitle'
        let body = 'scenes.ekyc.tips.content-web'

        if (this.isDestinationAddressRequired) {
          subtitle = 'scenes.ekyc.tips-destination-address.subtitle'
          body = 'scenes.ekyc.tips-destination-address.content-web'
        }

        return {
          subtitle: this.$t(subtitle),
          body: this.$t(body),
        }
      },
    },
  }
</script>
