var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "kycVerifyModal-content-privacy"
  }, [_vm.privacyInfo.kyc_type === 'agreement_checkbox' ? [_c('b-form-checkbox', {
    staticClass: "new-checkbox",
    attrs: {
      "value": true,
      "unchecked-value": false
    },
    model: {
      value: _vm.privacyInfo.checkbox,
      callback: function ($$v) {
        _vm.$set(_vm.privacyInfo, "checkbox", $$v);
      },
      expression: "privacyInfo.checkbox"
    }
  }, [_c('i18n', {
    attrs: {
      "path": "scenes.ekyc.tips.agreement-checkbox-policy",
      "tag": "p"
    },
    scopedSlots: _vm._u([{
      key: "providerPrivacyPolicy",
      fn: function () {
        return [_c('a', {
          attrs: {
            "href": _vm.privacyInfo.providerPolicyUrl,
            "target": "_blank"
          }
        }, [_vm._v("\n            " + _vm._s(_vm.privacyInfo.providerPolicy) + "\n          ")])];
      },
      proxy: true
    }, {
      key: "operatorName",
      fn: function () {
        return [_c('span', [_vm._v(_vm._s(_vm.privacyInfo.operatorName))])];
      },
      proxy: true
    }, {
      key: "operatorPrivacyPolicy",
      fn: function () {
        return [_c('a', {
          attrs: {
            "href": _vm.privacyInfo.privacy_policy_url,
            "target": "_blank"
          }
        }, [_vm._v("\n            " + _vm._s(_vm.$t('global.privacy')) + "\n          ")])];
      },
      proxy: true
    }], null, false, 819379262)
  })], 1)] : _vm.privacyInfo.kyc_type === 'agreement' ? [_c('i18n', {
    attrs: {
      "path": "scenes.ekyc.tips.agreement-policy",
      "tag": "p"
    },
    scopedSlots: _vm._u([{
      key: "providerPrivacyPolicy",
      fn: function () {
        return [_c('a', {
          attrs: {
            "href": _vm.privacyInfo.providerPolicyUrl,
            "target": "_blank"
          }
        }, [_vm._v("\n          " + _vm._s(_vm.privacyInfo.providerPolicy) + "\n        ")])];
      },
      proxy: true
    }, {
      key: "operatorName",
      fn: function () {
        return [_c('span', [_vm._v(_vm._s(_vm.privacyInfo.operatorName))])];
      },
      proxy: true
    }, {
      key: "operatorPrivacyPolicy",
      fn: function () {
        return [_c('a', {
          attrs: {
            "href": _vm.privacyInfo.privacy_policy_url,
            "target": "_blank"
          }
        }, [_vm._v("\n          " + _vm._s(_vm.$t('global.privacy')) + "\n        ")])];
      },
      proxy: true
    }])
  })] : [_c('i18n', {
    attrs: {
      "path": "scenes.ekyc.tips.no-agreement-policy",
      "tag": "p"
    },
    scopedSlots: _vm._u([{
      key: "providerPrivacyPolicy",
      fn: function () {
        return [_c('a', {
          attrs: {
            "href": _vm.privacyInfo.providerPolicyUrl,
            "target": "_blank"
          }
        }, [_vm._v("\n          " + _vm._s(_vm.privacyInfo.providerPolicy) + "\n        ")])];
      },
      proxy: true
    }])
  })]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }