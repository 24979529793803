var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tw-flex tw-flex-col tw-gap-6"
  }, [_c('div', {
    staticClass: "tips-description tp-6",
    domProps: {
      "innerHTML": _vm._s(_vm.content.subtitle)
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "tips-description tp-6",
    domProps: {
      "innerHTML": _vm._s(_vm.content.body)
    }
  }), _vm._v(" "), _c('Privacy', {
    attrs: {
      "privacy-info": _vm.privacyInfo
    }
  }), _vm._v(" "), _c('button', {
    staticClass: "kycVerifyModal-continueBtn call-to-action dark d-block btn-primary-hv",
    attrs: {
      "data-testid": "eKYC-continue-button"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, [_vm._v("\n    " + _vm._s(_vm.$t('global.start-ekyc-verification')) + "\n  ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }