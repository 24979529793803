<template>
  <div class="c--sim_detail" :class="`sim-detail theme-${sim.operator.style}`">
    <div
      class="sim-detail-header"
      data-testid="sim-detail-header"
      :style="`background: linear-gradient(${$dir() === 'rtl' ? 270 : 90}deg,${sim.operator.gradient_start} 0,${
        sim.operator.gradient_end
      })`"
    >
      <div
        :class="[
          'sim-detail-close airalo-icon-close-round-filled tw-text-5.5',
          sim.operator.style === 'dark' ? 'tw-text-neutral-600' : 'tw-text-white',
        ]"
        @click="closeModal"
      />

      <div
        :class="['sim-detail-operator', { 'tw-flex tw-justify-between tw-items-center tw-w-full': simHasPromotion }]"
        data-testid="sim-detail-operator-title"
      >
        <p>{{ sim.operator.title }}</p>

        <div>
          <template v-if="simHasPromotion && sim.is_stock">
            <PromotionBadge :percentage="pkgMxn_packagePromotionDiscount" />
          </template>
        </div>
      </div>
      <div class="sim-detail-top">
        <!-- Operator Image -->
        <div class="sim-detail-image">
          <template v-if="sim.operator.image && sim.operator.image.url">
            <img
              :data-src="sim.operator.image.url"
              :src="`/assets/images/placeholders/sim-operator-big-${
                sim.operator.style === 'dark' ? 'light' : 'dark'
              }.svg`"
              width="335"
              alt=""
              v-lazy-load
            />
          </template>
          <template v-else>
            <img
              :src="`/assets/images/placeholders/sim-operator-big-${
                sim.operator.style === 'dark' ? 'light' : 'dark'
              }.svg`"
              width="335"
              alt
              data-not-lazy
            />
          </template>
        </div>
        <div class="sim-detail-info tw-relative">
          <ul class="sim-detail-info-list" data-testid="sim-detail-info-list">
            <!-- Freemium -->
            <li v-if="sim.freemium && sim.freemium.is_freemium">
              <sim-item-row
                :theme="sim.operator.style"
                icon="airalo-icon-star"
                :key-text="$t('components.freemium.welcome-esim')"
                :value="$t('components.freemium.free')"
              />
            </li>
            <!-- Coverage -->
            <li>
              <sim-item-row
                :theme="sim.operator.style"
                icon="airalo-icon-globe-2"
                :key-text="$t('global.coverage')"
                :value="
                  sim.operator.type === 'local'
                    ? sim.operator.country.title
                    : `${sim.operator.countries.length} ${$t('global.countries')}`
                "
              ></sim-item-row>
            </li>
            <!-- Data -->
            <li v-if="sim.fair_usage_policy">
              <sim-item-row
                :theme="sim.operator.style"
                icon="airalo-icon-arrows-up-and-down"
                :key-text="$t('global.data')"
                :value="sim.data"
                :is-sub="true"
                sub-icon="ic_fup"
                :sub-text="sim.fair_usage_policy"
              ></sim-item-row>
            </li>
            <li v-else>
              <sim-item-row
                :theme="sim.operator.style"
                icon="airalo-icon-arrows-up-and-down"
                :key-text="$t('global.data')"
                :value="pkgMxn_packageHasUnlimitedData ? $t('global.data-unlimited') : sim.data"
              ></sim-item-row>
            </li>
            <!-- Voice -->
            <li v-if="pkgMxn_packageHasDVTVoice">
              <sim-item-row
                :theme="sim.operator.style"
                :icon-is-svg="true"
                icon="ic_calls"
                :key-text="$t('global.calls')"
                :value="
                  pkgMxn_packageHasUnlimitedVoice
                    ? $t('global.calls-unlimited')
                    : $t('global.minutes', {
                        amount: sim.voice,
                      })
                "
              ></sim-item-row>
            </li>
            <!-- Text -->
            <li v-if="pkgMxn_packageHasDVTText">
              <sim-item-row
                :theme="sim.operator.style"
                :icon-is-svg="true"
                icon="ic_sms"
                :key-text="$t('global.texts')"
                :value="
                  pkgMxn_packageHasUnlimitedText
                    ? $t('global.texts-unlimited')
                    : $t('global.sms', {
                        amount: sim.text,
                      })
                "
              ></sim-item-row>
            </li>
            <!-- Validity -->
            <li>
              <sim-item-row
                :theme="sim.operator.style"
                icon="airalo-icon-calendar"
                :key-text="$t('global.validity')"
                :value="`${sim.day} ${dayText(sim.day)}`"
                :is-last="false"
              ></sim-item-row>
            </li>
            <!-- Price -->
            <li v-if="sim.is_stock">
              <sim-item-row
                :theme="sim.operator.style"
                :icon-is-svg="true"
                icon="ic_price_v2"
                :key-text="$t('global.price')"
                :value="pkgMxn_packageNonPromotedPrice.formatted"
                :is-last="true"
                :is-price="true"
                :price-has-promotion="simHasPromotion && sim.is_stock"
                :is-promoted-price="false"
              ></sim-item-row>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div
      class="sim-detail-content"
      :class="{
        'bottom-rounded': isCheckoutByBuyerLocationRestricted,
      }"
    >
      <div class="sim-detail-available-packages-wrapper">
        <div class="sim-detail-available-packages-header" data-testid="top-up-header">
          <p>
            {{ $t('scenes.package.detail.available-top-ups') }}
            <span v-if="availablePackages && !!availablePackages.length">({{ availablePackages.length }})</span>
          </p>
        </div>

        <!-- Top-up Packages -->
        <template v-if="availablePackagesLoader">
          <div class="relative preloader-min-h-236px">
            <preloader :value="true" class="relative" />
          </div>
        </template>
        <template v-else>
          <div class="sim-detail-available-packages no-available-packages" v-if="!availablePackages.length">
            <p class="no-available-packages" data-testid="no-available-packages-message">
              {{ $t('scenes.topup.list.no-topup-title') }}
            </p>
          </div>
          <div class="sim-detail-available-packages" v-else>
            <CardPackageCarousel
              :main_package="sim"
              :sim_packages="availablePackages"
              :sim_isdark="sim.operator.style === 'dark'"
              :sim_gradient_start="sim.operator.gradient_start"
              :sim_gradient_end="sim.operator.gradient_end"
            />
          </div>
        </template>
      </div>
      <div class="d-grid grid-cols-1 grid-cols-sm-2 gap-30 mt-35 mt-sm-40 mb-40">
        <!-- Supported Countries -->
        <div>
          <country-list
            :title="$t('components.sim.modal.supported-countries')"
            :list="sim.operator.countries"
            :is-shadowed-box="true"
            :is-rounded-box="true"
            :search-input-placeholder="$t('placeholder.supported.countries.search')"
          />
        </div>
        <!-- Additional Information -->
        <div>
          <additional-information
            :type="sim.operator.type"
            :title="$t('scenes.package.detail.aditional-info')"
            :network-list="sim.operator.type === 'global' ? globalNetworks : sim.operator.networks"
            :plan-type="sim.operator.plan_type"
            :activation-policy="sim.operator.activation_policy"
            :calling-credit="sim.calling_credit ? sim.calling_credit : ''"
            :e-kyc="!!sim.operator.is_kyc_verify"
            :e-kyc-restriction="sim.operator && sim.operator.kyc_restriction ? sim.operator.kyc_restriction : null"
            :e-kyc-validation="
              sim.operator && sim.operator.is_kyc_one_time && sim.operator.kyc_expiry_duration
                ? sim.operator.kyc_expiry_duration
                : null
            "
            :is-kyc-one-time="isKycOneTime"
            :topup-option="sim.operator.rechargeability"
            :otherInfo="sim.operator.other_info && sim.operator.other_info.length > 0 ? sim.operator.other_info : ''"
            :is-shadowed-box="true"
            :is-rounded-box="true"
            :has-routing-section="hasRoutingSection"
            :is-routing="!!sim.operator.is_routing"
            @onOpenNetworksModal="onOpenNetworksModal"
          />
        </div>
      </div>

      <WarningV2
        v-if="isCheckoutByBuyerLocationRestricted"
        :message="$t('components.popup.uae-restriction-description')"
      />

      <template v-else-if="kycRequired()">
        <KYCRejectedWarning
          class="esim-popup-kyc-rejected-warning"
          :isGoDetail="isGoToeKycDetail"
          :translate="getTranslateWithKYCType"
          :kyc-identity="kycIdentity"
        />
      </template>
    </div>

    <div
      v-if="!isCheckoutByBuyerLocationRestricted"
      data-testid="sim-details-footer"
      class="sim-detail-bottom"
      :class="{
        'tw-promo-prices-bg': footerHasPromotionBackground,
      }"
    >
      <!-- eSIM Quota Exceeded -->
      <div v-if="isSimQuotaExceeded()">
        <p class="text-center mt-4 mb-0">
          {{ $t('global.kyc.simlimit', { count: sim.operator.country.sim_limit }) }}
        </p>
      </div>

      <!-- Buy Button Zone -->
      <div
        v-else-if="!$store.state.auth.loggedIn || !sim.operator.is_kyc_verify || getKYCType !== 'pending'"
        class="tw-flex tw-flex-col sm:tw-flex-row tw-gap-2 sm:tw-gap-7.5"
        :class="{ promoted: simHasPromotion }"
      >
        <sim-promotion-loyalty-reward
          v-if="showLoyaltyReward"
          class="tw-mr-2 tw-flex-nowrap sm:tw-hidden"
          :item="sim"
          text-size="sm"
          :heading-text="$t('components.branding.loyalty.estimated-rewards')"
          :heading-bold="false"
          text-column-orientation
          info-tooltip
          :info-tooltip-text="$t('components.branding.loyalty.estimated-rewards-disclaimer')"
          info-tooltip-position="right"
        />

        <!-- Destination address missing -->
        <template v-if="isKycDestinationAddressMissing">
          <div
            class="call-to-action dark std-alone-sized-larger btn-primary-hv"
            @click="showDestinationAddressModal = true"
          >
            {{ $t('global.ekyc.destination-address-missing') }}
          </div>
        </template>

        <!-- Verify Your Identity -->
        <template v-else-if="kycRequired() && isKycRejectedOrExpired">
          <div
            :class="[
              'call-to-action dark std-alone-sized-larger',
              { 'btn-primary-hv': !isCoBranded, 'btn-cobranded': isCoBranded },
            ]"
            :style="{
              '--brand-colour': coBrandedBrandColour,
              '--brand-text-colour': coBrandedTextColour,
            }"
            data-testid="eKYC-button"
            @click="toCompleteKyc"
          >
            {{ $t('global.ekyc-procced-resubmit') }}
          </div>
        </template>

        <template v-else-if="sim.is_stock">
          <!-- SharedSalePrice for desktop -->
          <div class="tw-hidden sm:tw-inline-flex tw-flex-row tw-w-1/2">
            <sim-promotion-loyalty-reward
              v-if="showLoyaltyReward"
              class="tw-mr-3 rtl:tw-ml-3"
              :item="sim"
              text-size="sm"
              :heading-text="$t('components.branding.loyalty.estimated-rewards')"
              :heading-bold="false"
              text-column-orientation
              info-tooltip
              :info-tooltip-text="$t('components.branding.loyalty.estimated-rewards-disclaimer')"
            />

            <SharedSalePrice
              v-if="simHasPromotion"
              class="tw-text-white"
              :discountOrFormattedPrice="pkgMxn_packagePromotionDiscount"
              :displayIcon="true"
              :isDiscount="true"
              :salePriceEndDateIso="pkgMxn_packagePromotionEndDateIso"
              :tooltipTargetId="`package-detail-bottom-button-${sim.id}-desktop`"
            />
            <div
              class="tw-flex tw-items-center tw-grow tw-font-semibold tw-text-5"
              :class="simHasPromotion ? 'tw-text-white tw-justify-end' : 'tw-text-primary tw-justify-start'"
              data-testid="esim-detail-package-price"
            >
              {{ pkgMxn_packageCurrentPrice.formatted }}
            </div>
          </div>

          <!-- SharedSalePrice for mobile -->
          <div class="tw-flex sm:tw-hidden tw-flex-row tw-w-1/2">
            <SharedSalePrice
              v-if="simHasPromotion"
              class="tw-text-white tw-hidden sm:tw-inline-flex"
              :discountOrFormattedPrice="pkgMxn_packageCurrentPrice.formatted"
              :displayIcon="true"
              :isDiscount="false"
              :salePriceEndDateIso="pkgMxn_packagePromotionEndDateIso"
              :tooltipTargetId="`package-detail-bottom-button-${sim.id}-mobile`"
            />
            <div
              v-else
              class="tw-hidden tw-items-center tw-grow tw-text-7 tw-font-semibold tw-text-primary tw-justify-start sm:tw-flex"
            >
              {{ pkgMxn_packageCurrentPrice.formatted }}
            </div>
          </div>
          <!-- BUY Button -->
          <div class="tw-flex tw-w-full tw-gap-2 sm:tw-w-1/2">
            <SharedSalePrice
              v-if="simHasPromotion"
              class="tw-text-white tw-inline-flex sm:tw-hidden tw-grow tw-shrink-0"
              :discountOrFormattedPrice="pkgMxn_packageCurrentPrice.formatted"
              :displayIcon="true"
              :isDiscount="false"
              :salePriceEndDateIso="pkgMxn_packagePromotionEndDateIso"
              :tooltipTargetId="`package-detail-bottom-button-${sim.id}-mobile`"
            />
            <div
              v-else
              class="tw-flex tw-items-center tw-grow tw-text-7 tw-font-semibold tw-text-primary tw-justify-start sm:tw-hidden tw-shrink-0"
            >
              {{ pkgMxn_packageCurrentPrice.formatted }}
            </div>
            <b-button
              block
              class="topup-package-price-btn tw-font-semibold tw-text-4.5 tw-leading-4 tw-py-4"
              :class="{ 'btn-cobranded': isCoBranded }"
              :style="{
                '--brand-colour': coBrandedBrandColour,
                '--brand-text-colour': coBrandedTextColour,
              }"
              data-testid="buy-button"
              @click="toBuyESim"
            >
              {{ $t('global.buy') }}
            </b-button>
          </div>
        </template>
        <div
          v-else
          @click="toNotifyESim"
          class="call-to-action dark std-alone-sized-larger btn-primary-hv"
          data-testid="notify-stock-button"
        >
          {{
            isNotifiedSim
              ? $t('scenes.package.detail.cta-button-remove-notify')
              : $t('scenes.package.detail.cta-button-notify')
          }}
        </div>
      </div>
    </div>

    <client-only>
      <prompt-modal
        :modal-view="notifyMeModal"
        :overlay-view="notifyMeModalOverlay"
        type="double"
        :text="
          isNotifiedSim
            ? $t('components.stock.remove-notification-title')
            : $t('components.stock.add-notification-title')
        "
        :button="{
          text: $t('global.cancel'),
          callbackName: 'closePromptModal',
          isClose: true,
        }"
        :button-second="{
          text: isNotifiedSim
            ? $t('components.stock.remove-notification-positive-button')
            : $t('components.stock.add-notification-positive-button'),
          callbackName: !isNotifiedSim ? 'addPackageReminder' : 'removePackageReminder',
          class: isNotifiedSim ? 'red' : '',
          variant: !isNotifiedSim ? 'prompt-simple-secondary' : false,
        }"
        @onCloseModal="closePromptModal"
        @onClickButton="clickPromptModalActions"
      >
        <div v-if="!isNotifiedSim" class="mt-10">
          <p class="outofstock-notify-desc m-none">
            {{ $t('messages.notification.out-of-stock-add') }}
          </p>

          <div class="d-flex align-items-center justify-content-start mt-20 mb-20">
            <Toggle class="v-middle" :value="notifyEmailToo" v-on:change="notifyEmailToo = arguments[0]" />
            <p
              @click="notifyEmailToo = !notifyEmailToo"
              :class="['toggle-label m-none', { 'ml-10': $dir() === 'ltr', 'mr-10': $dir() === 'rtl' }]"
            >
              {{ $t('components.stock.add-notification-switch-text') }}
            </p>
          </div>
        </div>
      </prompt-modal>

      <KYCVerifyModal />

      <template v-if="kycIdentity">
        <DestinationAddressModal
          :show="showDestinationAddressModal"
          :identity="kycIdentity"
          :id="sim.id"
          @close="closeDestinationAddressModal"
        />
      </template>

      <template v-if="sim.operator.type === 'global'">
        <NetworksModal
          modal-id="sim-global-networks-modal"
          :is-networks-modal="true"
          :modal-view="networksModalView"
          :countries="globalNetworks"
          :title="$t('components.supported.countries.modal.network-title')"
          :description="$t('components.supported.countries.modal.network-description')"
          :search-input-placeholder="$t('placeholder.supported.countries.modal.network-search')"
          :empty-text="$t('global.no-country-available')"
          @onCloseSupportedCountriesModal="onCloseNetworksModal"
        />
      </template>
    </client-only>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import urlJoin from 'url-join'
  import pkgMxn from '~/mixins/package.js'
  import AdditionalInformation from '~/components/sim/additional-information'
  import CardPackageCarousel from '~/components/sim/card-package-carousel.vue'
  import CountryList from '~/components/shared/country-list'
  import KYCRejectedWarning from '~/components/homepage/kyc-rejected-warning.vue'
  import KYCVerifyModal from '~/components/shared/kyc-verify-modal'
  import NetworksModal from '~/components/modals/supported-countries-modal'
  import Preloader from '~/components/inner-preloader.vue'
  import PromotionBadge from '~/components/sim/promotion-badge.vue'
  import PromptModal from '~/components/modals/prompt-simple.vue'
  import SharedSalePrice from '~/components/shared/sale-price.vue'
  import SimItemRow from '~/components/sim/item-row.vue'
  import Toggle from '~/components/input/toggle.vue'
  import WarningV2 from '~/components/banners/WarningV2.vue'
  import SimPromotionLoyaltyReward from '~/components/sim/promotion-loyalty-reward.vue'
  import DestinationAddressModal from '@/components/modals/destination-address.vue'

  export default {
    name: 'ESimDetails',

    components: {
      DestinationAddressModal,
      AdditionalInformation,
      CardPackageCarousel,
      CountryList,
      KYCRejectedWarning,
      KYCVerifyModal,
      NetworksModal,
      Preloader,
      PromotionBadge,
      PromptModal,
      SharedSalePrice,
      SimItemRow,
      SimPromotionLoyaltyReward,
      Toggle,
      WarningV2,
    },

    mixins: [pkgMxn(true, 'sim')],

    props: ['country', 'sim', 'modal'],

    data() {
      return {
        appUrl: process.env.APP_URL || 'https://airalo.com',
        availablePackages: null,
        availablePackagesLoader: true,
        hasAdditionalRoutingRow: false,
        isNotifiedSim: false,
        networksModalView: false,
        notifyEmailToo: true,
        notifyMeModal: false,
        notifyMeModalOverlay: false,
        notifyNotificationToo: true,
        showStickyButton: false,
        showDestinationAddressModal: false,
        globalNetworks: [],
      }
    },

    head() {
      const productStructuredData = {
        '@context': 'https://schema.org',
        '@type': 'Product',
        productID: `${this.sim.slug}`,
        brand: `Airalo`,
        name: `${this.country.title} ${this.sim.title}`,
        description: `${this.sim.operator.info.join(' ')}`,
        url: `${urlJoin(this.appUrl || '', this.country.slug || '', this.sim.slug || '')}`,
        image: this.sim.operator.image?.url ? `${this.sim.operator.image.url}` : '',
        offers: {
          '@type': 'Offer',
          price: `${this.pkgMxn_packageCurrentPrice}`,
          priceCurrency: 'USD',
          itemCondition: 'https://schema.org/NewCondition',
          availability: 'https://schema.org/InStock',
        },
      }

      return {
        script: [
          {
            innerHTML: JSON.stringify(productStructuredData),
            type: 'application/ld+json',
          },
        ],
        __dangerouslyDisableSanitizers: ['script'],
      }
    },

    computed: {
      ...mapGetters({
        isCoBranded: 'coBranding/getIsCoBranded',
        branding: 'coBranding/getBranding',
        currentCurrencyCode: 'multiCurrency/get_current_currency_code',
      }),

      coBrandedBrandColour() {
        return this.branding?.background_color || ''
      },

      coBrandedTextColour() {
        return this.branding?.text_color || ''
      },

      showLoyaltyReward() {
        return (
          !this.simHasPromotion &&
          this.isCoBranded &&
          this.branding?.loyalty_program_unit &&
          this.branding?.loyalty_program_usd_conversion &&
          this.currentCurrencyCode === 'USD'
        )
      },

      simHasPromotion() {
        return this.pkgMxn_packageHasPromotion
      },

      kycIdentity: {
        get() {
          if (this.sim.operator.is_kyc_one_time) {
            return this.$store.getters['eKyc/get_package_identity'] || null
          } else {
            return this.$store.getters['eKyc/get_user_default_identities'] || null
          }
        },
      },

      getKYCType() {
        const kycStatus = this.kycIdentity?.status

        if (!kycStatus) {
          return 'not-verified'
        } else {
          return kycStatus
        }
      },

      isKycRejectedOrExpired() {
        return ['not-verified', 'rejected', 'expired', 'pending'].includes(this.getKYCType)
      },

      getTranslateWithKYCType() {
        const kycStatus = this.getKYCType

        if (kycStatus === 'not-verified') {
          return this.$t('components.ekyc.warning.not-verified')
        } else if (kycStatus === 'pending') {
          if (this.sim.operator.is_kyc_one_time) {
            const operatorName = this.sim.operator?.title || ''
            return this.$t('components.ekyc.warning.one-time-verifying', {
              operatorName,
            })
          } else {
            return this.$t('components.ekyc.warning.default-verifying')
          }
        } else if (kycStatus === 'rejected') {
          return this.$t('components.ekyc.warning.rejected')
        } else if (kycStatus === 'expired') {
          return this.$t('components.ekyc.warning.expired')
        } else if (kycStatus === 'approved') {
          return this.$t('components.ekyc.warning.approved')
        } else if (kycStatus === 'DESTINATION_ADDRESS_MISSING') {
          return this.$t('components.ekyc.warning.destination-address-missing')
        }
      },

      isGoToeKycDetail() {
        const rejectedStatus = ['approved', 'rejected', 'expired', 'pending']

        return rejectedStatus.includes(this.getKYCType)
      },

      hasRoutingSection() {
        return (
          this.hasAdditionalRoutingRow &&
          'is_routing' in this.sim.operator &&
          typeof this.sim.operator.is_routing === 'boolean'
        )
      },

      isCheckoutByBuyerLocationRestricted() {
        return this.$store.getters['behaviors/get_is_checkout_by_buyer_location_restricted']
      },

      footerHasPromotionBackground() {
        return (
          this.simHasPromotion &&
          this.sim.is_stock &&
          !this.isSimQuotaExceeded() &&
          !(this.kycRequired() && !['approved'].includes(this.getKYCType))
        )
      },

      isKycOneTime() {
        return !(
          (!this.sim?.operator?.is_kyc_one_time && !this.sim?.operator?.is_operator_kyc) ||
          (this.sim?.operator?.is_kyc_one_time && this.sim?.operator?.is_operator_kyc)
        )
      },

      isDestinationAddressRequired() {
        return this.sim?.operator?.kyc_addr_required
      },

      isKycDestinationAddressMissing() {
        return ['DESTINATION_ADDRESS_MISSING'].includes(this.getKYCType)
      },
    },

    methods: {
      kycRequired() {
        return this.$store.state.auth.loggedIn && this.kycRequiredGuest()
      },

      kycRequiredGuest() {
        return this.sim.operator.is_kyc_verify
      },

      isSimQuotaExceeded() {
        if (this.sim.operator.region_id || !this.sim.operator.country) {
          return false
        }

        let esims = this.$store.state.user_kyc_esims
        let country = this.sim.operator.country
        let slug = country.slug

        return (
          this.$store.state.auth.loggedIn &&
          country.sim_limit > 0 &&
          esims &&
          esims.hasOwnProperty(slug) &&
          esims[slug] >= country.sim_limit
        )
      },

      closeModal() {
        this.$emit('clickClose')
      },

      toCompleteKyc() {
        const privacyInfo = {
          kyc_type: this.sim.operator.kyc_type || 'no_agreement',
          privacy_policy_url: this.sim.operator.privacy_policy_url || '',
          operatorName: this.sim.operator.title || '',
        }
        const package_id = this.sim.operator?.is_kyc_one_time ? this.sim.id : undefined
        const isOneTime = !!package_id

        this.$utils.sendEvent('eKycEvent', { interaction: 'esim details', esim: this.sim })
        this.$store.commit('eKyc/SET_VERIFY_MODAL_DATA', {
          isShow: true,
          privacyInfo,
          package_id,
          isOneTime,
          isDestinationAddressRequired: this.isDestinationAddressRequired,
        })
      },

      toBuyESim() {
        const URL = `/esim-buy/${this.sim.slug || this.sim.id}`
        document.getElementsByTagName('body')[0].classList.remove('no-overflow')

        if (process.client && !this.$store.state.auth.loggedIn) {
          localStorage.setItem('redirect-url', URL)
        } else {
          if (localStorage.getItem('redirect-url')) {
            localStorage.removeItem('redirect-url')
          }
        }

        const value = {
          item_id: this.sim.id,
          item_name: this.sim.title,
          currency: this.$store.state.multiCurrency.current_currency.code,
          value: this.pkgMxn_packageCurrentPrice,
        }

        this.$utils.sendEvent('buyButtonTappedEvent', value)
        this.$utils.sendEvent('addToCart', this.sim)

        this.$emit('clickCallToAction', {
          route: {
            name: 'esim-buy-id',
            params: {
              id: this.sim.slug || this.sim.id,
            },
          },
        })
      },

      closePromptModal() {
        this.notifyMeModal = false
      },

      clickPromptModalActions(e, data) {
        this[data.callbackName](data.type)
      },

      toNotifyESim() {
        if (process.client && !this.$store.state.auth.loggedIn) {
          document.getElementsByTagName('body')[0].classList.remove('no-overflow')
          localStorage.setItem('redirect-url', window.location.pathname)
          this.$router.push(this.localePath('/auth/login'))

          this.$emit('clickCallToAction', {
            route: {
              name: 'auth-index-login',
            },
          })
        } else {
          this.notifyMeModal = true
        }
      },

      async addPackageReminder() {
        this.notifyMeModalOverlay = true

        try {
          await this.$axios.post(`v2/user/me/package/${this.sim.id}/reminders/`, {
            ...(this.notifyEmailToo && { is_email: this.notifyEmailToo }),
            is_push: true,
          })

          this.isNotifiedSim = !this.isNotifiedSim

          const text = this.$t('messages.success.add-stock-notification', {
            operatorName: this.sim.operator.title,
          })
          this.$bvToast.toast(text, {
            title: this.$t('messages.success.title'),
            variant: 'success',
            solid: true,
            hidden: () => {},
          })
        } catch (error) {
        } finally {
          this.notifyMeModalOverlay = false
          this.notifyMeModal = false
        }
      },

      async removePackageReminder() {
        this.notifyMeModalOverlay = true

        try {
          await this.$axios.delete(`v2/user/me/package/${this.sim.id}/reminders/`)

          this.isNotifiedSim = !this.isNotifiedSim

          this.$bvToast.toast(this.$t('messages.success.remove-stock-notification'), {
            title: this.$t('messages.success.title'),
            variant: 'success',
            solid: true,
            hidden: () => {},
          })
        } catch (error) {
        } finally {
          this.notifyMeModalOverlay = false
          this.notifyMeModal = false
        }
      },

      fetchStockReminder() {
        setTimeout(() => {
          this.$axios
            .get(`v2/user/me/package/${this.sim.id}/reminders/`)
            .then(() => {
              this.isNotifiedSim = true
            })
            .catch(() => {
              this.isNotifiedSim = false
            })
        }, 500)
      },

      dayText(day) {
        return parseInt(day) < 2 ? this.$t('global.day') : this.$t('global.days')
      },

      onOpenNetworksModal() {
        this.networksModalView = true
      },

      onCloseNetworksModal() {
        this.networksModalView = false
      },

      clickCallToAction() {
        if (this.kycRequired() && this.isKycRejectedOrExpired) this.toCompleteKyc()
        else if (this.sim.is_stock) this.toBuyESim()
        else this.toNotifyESim()
      },

      fetchPackageIdentity() {
        this.$store.dispatch('eKyc/fetch_package_identity', this.sim.id)
      },

      async getAvailablePackages(id) {
        try {
          this.availablePackages = await this.$store.dispatch('fetch_operator_topup_packages', id)
        } catch (error) {
          this.availablePackages = []
        } finally {
          this.availablePackagesLoader = false
        }
      },

      async closeDestinationAddressModal(val) {
        if (val === 'success') {
          await this.$store.dispatch('eKyc/fetch_package_identity', this.sim.id)
        }

        this.showDestinationAddressModal = false
      },
    },

    destroyed() {
      this.$store.commit('eKyc/RESET_PACKAGE_IDENTITY')
    },

    async created() {
      this.hasAdditionalRoutingRow = this.$featureFlag.isActive('additional_info_routing_row')
    },

    async mounted() {
      if (this.sim.operator.type === 'global') {
        try {
          this.globalNetworks = await this.$axios.$get(`/v2/operators/${this.sim.operator.id}/networks`)
        } catch (error) {
          this.globalNetworks = []
        }
      }

      const value = {
        item_id: this.sim?.slug,
        item_name: this.sim?.title,
        currency: this.$store.state.multiCurrency.current_currency.code,
        value: this.pkgMxn_packageCurrentPrice,
      }
      this.$utils.sendEvent('viewItemEvent', value)
      this.$utils.sendEvent('transactionEvent', this.sim)

      if (this.sim) {
        this.$utils.sendEvent('viewItem', this.sim)
        this.getAvailablePackages(this.sim.operator.id)
      }

      if (process.client && this.$store.state.auth.loggedIn) {
        this.fetchStockReminder()
        this.kycRequiredGuest() && this.sim.operator.is_kyc_one_time && this.fetchPackageIdentity()
      }
    },
  }
</script>

<style>
  .outofstock-notify .call-to-action.notify-me {
    color: #ffffff;
    background-color: #4a4a4a;
  }

  .outofstock-notify .call-to-action.dont-notify {
    color: #f2222e;
  }
</style>

<style lang="scss" scoped>
  .preloader-min-h-236px {
    min-height: 236px;
  }
  .col-btn-full {
    @media only screen and (max-width: 575px) {
      padding: 0;

      .call-to-action {
        width: 100% !important;
      }
    }
  }

  .call-to-action.dark {
    line-height: 42px;
    padding-top: 0px;
    padding-bottom: 0px;
    font-weight: bold;
  }

  .sticky-button {
    display: block;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    bottom: 0;
    z-index: 23;

    @media only screen and (max-width: 768px) {
      bottom: 20px;
    }
  }

  @media (max-width: 414px) {
    .call-to-action.dark {
      width: 100% !important;
    }
  }

  .outofstock-notify-btns {
    line-height: 42px;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: bold;
  }

  .outofstock-notify-title {
    color: #4a4a4a;
    font-size: 21px;
    font-weight: 600;
    line-height: 25px;
    text-align: center;
  }

  .outofstock-notify-desc {
    color: #8a8a8a;
    font-size: 0.9375rem;
    letter-spacing: 0;
    line-height: 1.4;
    text-align: center;
    margin-top: 10px;
  }

  .toggle-label {
    color: #4a4a4a;
    font-size: 0.8125rem;
    line-height: 1.2;
    max-width: calc(100% - 58px);
  }

  .outofstock-notify-btns-wrapper {
    margin-top: 20px;
  }

  .sim-detail {
    display: flex;
    flex-direction: column;
    height: 100%;

    &.theme-light {
      .sim-detail-operator {
        p {
          color: #ffffff;
        }
      }
    }

    &.theme-dark {
      .sim-detail-operator {
        p {
          color: #4a4a4a;
        }
      }
    }

    &-close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
      z-index: 11;

      [dir='rtl'] & {
        right: unset;
        left: 10px;
      }
    }

    &-header {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 40px;
      position: relative;
      z-index: 10;
      border-radius: 7px 7px 0 0;

      @media only screen and (max-width: 768px) {
        padding: 40px 30px 30px 30px;
        border-radius: 0;
      }

      @media only screen and (max-width: 575px) {
        padding: 40px 20px 20px 20px;
      }
    }

    &-operator {
      margin-bottom: 30px;

      @media only screen and (max-width: 768px) {
        margin-bottom: 20px;
      }

      p {
        font-size: 1.6875rem;
        font-weight: 600;
        letter-spacing: -0.5px;
        line-height: 1.1851;
        margin: 0;

        @media only screen and (max-width: 768px) {
          font-size: 1.3125rem;
        }
      }
    }

    &-top {
      width: 100%;
      display: flex;
      align-items: flex-start;

      @media only screen and (max-width: 575px) {
        flex-direction: column;
        justify-content: flex-start;
      }
    }

    &-image {
      width: 335px;
      border-radius: 29px;
      box-shadow: 0 12px 36px 0 rgba(0, 0, 0, 0.3);
      overflow: hidden;
      position: relative;

      @media only screen and (max-width: 575px) {
        max-width: unset;
        width: 100%;
      }

      &::before {
        content: '';
        display: block;
        position: relative;
        width: 100%;
        padding-top: 62.6865671642%;
      }

      img {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &-info {
      width: calc(100% - 335px);
      padding-left: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      [dir='rtl'] & {
        padding-left: unset;
        padding-right: 30px;

        @media only screen and (max-width: 575px) {
          padding-left: unset;
          padding-right: 0;
        }
      }

      @media only screen and (max-width: 768px) {
        width: 50%;
      }

      @media only screen and (max-width: 575px) {
        width: 100%;
        padding-left: 0;
        margin-top: 30px;
      }

      &-list {
        width: 100%;
      }
    }

    &-content {
      padding: 40px;
      background-color: #fff;
      border-radius: 0;
      &.bottom-rounded {
        border-radius: 0 0 7px 7px;
      }

      @media only screen and (max-width: 768px) {
        padding: 30px;
        border-radius: 0;
      }

      @media only screen and (max-width: 575px) {
        padding: 20px;
        overflow: unset;
      }
    }

    &-available-packages {
      margin-top: 20px;

      &.no-available-packages {
        margin-top: 10px;
      }

      &:not(.no-available-packages) {
        @media (max-width: 575px) {
          margin-left: -20px !important;
          margin-right: -20px !important;
        }

        &::v-deep .swiper-container {
          @media (max-width: 575px) {
            padding-left: 20px !important;
            padding-right: 20px !important;
          }
        }
      }

      &-header {
        p {
          margin: 0;
          font-size: 1.3125rem;
          font-weight: 600;
          letter-spacing: -0.3px;
          line-height: 1.190476;
          color: #4a4a4a;
        }
      }

      p {
        color: #8a8a8a;
        font-size: 0.9375rem;
        letter-spacing: 0;
        line-height: 1.4;
        margin: 0;
      }
    }

    &-bottom {
      background-color: rgba(250, 250, 250, 0.9);
      border-radius: 0 0 7px 7px;
      padding: 20px 40px;
      position: sticky;
      bottom: -40px;
      z-index: 23;

      @media (max-width: 767px) {
        border-radius: 0;
        bottom: 0;
        padding: 20px 30px;
      }

      @media (max-width: 575px) {
        padding: 20px;
      }
    }
  }

  .topup-package-price-btn {
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
    @apply tw-bg-primary;
    @apply tw-text-white;

    &:hover {
      @apply tw-bg-neutral-500;
    }

    .promoted & {
      @apply tw-bg-white;
      @apply tw-border-white;
      @apply tw-text-promo_prices-cta;
      &:hover {
        @apply tw-bg-white;
      }
    }
  }
</style>
