<template>
  <div class="kycVerifyModal-content-privacy">
    <template v-if="privacyInfo.kyc_type === 'agreement_checkbox'">
      <b-form-checkbox v-model="privacyInfo.checkbox" class="new-checkbox" :value="true" :unchecked-value="false">
        <i18n path="scenes.ekyc.tips.agreement-checkbox-policy" tag="p">
          <template #providerPrivacyPolicy>
            <a :href="privacyInfo.providerPolicyUrl" target="_blank">
              {{ privacyInfo.providerPolicy }}
            </a>
          </template>

          <template #operatorName>
            <span>{{ privacyInfo.operatorName }}</span>
          </template>

          <template #operatorPrivacyPolicy>
            <a :href="privacyInfo.privacy_policy_url" target="_blank">
              {{ $t('global.privacy') }}
            </a>
          </template>
        </i18n>
      </b-form-checkbox>
    </template>

    <template v-else-if="privacyInfo.kyc_type === 'agreement'">
      <i18n path="scenes.ekyc.tips.agreement-policy" tag="p">
        <template #providerPrivacyPolicy>
          <a :href="privacyInfo.providerPolicyUrl" target="_blank">
            {{ privacyInfo.providerPolicy }}
          </a>
        </template>

        <template #operatorName>
          <span>{{ privacyInfo.operatorName }}</span>
        </template>

        <template #operatorPrivacyPolicy>
          <a :href="privacyInfo.privacy_policy_url" target="_blank">
            {{ $t('global.privacy') }}
          </a>
        </template>
      </i18n>
    </template>

    <template v-else>
      <i18n path="scenes.ekyc.tips.no-agreement-policy" tag="p">
        <template #providerPrivacyPolicy>
          <a :href="privacyInfo.providerPolicyUrl" target="_blank">
            {{ privacyInfo.providerPolicy }}
          </a>
        </template>
      </i18n>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'kyc-verify-modal_privacy',
    props: {
      privacyInfo: {
        type: Object,
        required: true,
      },
    },
  }
</script>

<style lang="scss" scoped>
  .kycVerifyModal-content-privacy {
    color: #8a8a8a;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;

    p {
      margin-bottom: 0;
    }

    a {
      font-weight: 600;
    }
  }
</style>
