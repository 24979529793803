<template>
  <b-modal
    v-model="kycVerifyModalData.isShow"
    ref="kycVerifyModal"
    centered
    :size="showTips ? 'md' : 'xl'"
    :hide-footer="true"
    @hide="kycVerifyModalHidden"
    dialog-class="kycVerifyModal-dialog"
    header-class="kycVerifyModal-header"
    data-testid="eKYC-header"
    body-class="kycVerifyModal-body"
  >
    <template #modal-title>
      <h2 class="package-kycVerifyModal-title">
        {{ $t(kycVerifyModalTitle) }}
      </h2>
    </template>

    <template #modal-header-close>
      <i class="airalo-icon-close-round-filled tw-block tw-text-5.5 tw-mt-2.5 tw-mr-2.5" />
    </template>

    <div class="c--shared_kyc-verify-modal">
      <div v-if="showTips" class="kycVerifyModal-content" data-testid="eKYC-content">
        <template v-if="showDestinationAddress">
          <DestinationAddress @click="submitKyc" />
        </template>

        <template v-else>
          <VerificationTips
            :privacy-info="privacyInfo"
            :is-destination-address-required="kycVerifyModalData.isDestinationAddressRequired"
            @click="checkDestinationAddressOrSubmitKyc"
          />
        </template>
      </div>

      <template v-else>
        <b-embed
          type="iframe"
          :src="modalSrc"
          allow="camera;fullscreen;accelerometer;gyroscope;magnetometer"
          allowfullscreen
        />
      </template>
    </div>
  </b-modal>
</template>

<script>
  import DestinationAddress from '@/components/shared/kyc-verify-modal/destination-address.vue'
  import VerificationTips from '@/components/shared/kyc-verify-modal/verification-tips/index.vue'

  export default {
    name: 'kyc-verify-modal',
    components: {
      DestinationAddress,
      VerificationTips,
    },
    data() {
      return {
        showTips: true,
        showDestinationAddress: false,
        modalSrc: '',
        transactionId: null,
        destinationAddress: '',
        privacyInfo: {
          kyc_type: 'no_agreement',
          providerPolicy: this.$t('scenes.ekyc.tips.provider-policy'),
          providerPolicyUrl: this.$t('url.identity.authentication.provider-privacy-policy'),
        },
      }
    },
    computed: {
      kycVerifyModalData: {
        get() {
          const { privacyInfo } = this.$store.getters['eKyc/get_verify_modal_data']

          if (privacyInfo) {
            this.privacyInfo = {
              ...privacyInfo,
              ...(privacyInfo.kyc_type === 'agreement_checkbox' ? { checkbox: false } : null),
              providerPolicy: this.$t('scenes.ekyc.tips.provider-policy'),
              providerPolicyUrl: this.$t('url.identity.authentication.provider-privacy-policy'),
            }
          }

          return this.$store.getters['eKyc/get_verify_modal_data']
        },
      },
      kycVerifyModalTitle() {
        if (this.showTips) {
          if (this.showDestinationAddress) {
            return 'scenes.ekyc.destination-address.title'
          } else {
            return 'scenes.ekyc.tips.title'
          }
        } else {
          return 'scenes.ekyc.tips.navigation-title'
        }
      },
    },
    methods: {
      async requestJumio() {
        const requestData = {
          package_id: this.kycVerifyModalData.package_id || undefined,
        }

        if (this.kycVerifyModalData?.order_id) {
          requestData.order_id = this.kycVerifyModalData.order_id
        }

        if (this.kycVerifyModalData?.isDestinationAddressRequired) {
          requestData.destination = this.destinationAddress
        }

        try {
          const transaction = await this.$axios.post('/v2/users/me/identities', requestData)
          const data = transaction.data

          if (data) {
            if (data.web_url) {
              this.modalSrc = data.web_url.replace('locale=en', `locale=${this.$i18n.locale}`)
            }

            if (data.transaction_id) {
              this.transactionId = data.transaction_id
            }
          }
        } catch (error) {
          this.$bvToast.toast(error.response.data.message, {
            title: this.$t('global.error.title'),
            variant: 'danger',
            solid: true,
          })
          this.$refs.kycVerifyModal.hide()
        }
      },
      async updateStatus() {
        try {
          await this.$axios.put('/v2/users/me/identities/' + this.transactionId, {}).then((response) => {
            if (this.kycVerifyModalData.type === 'my-esims') {
              this.kycVerifyModalData.isOneTime
                ? this.$store.dispatch('eKyc/fetch_user_identities')
                : this.$store.dispatch('eKyc/fetch_user_default_identities')

              this.$root.$emit('update-eSIM')
            } else {
              this.kycVerifyModalData.isOneTime
                ? this.$store.dispatch('eKyc/fetch_package_identity', this.kycVerifyModalData.package_id)
                : this.$store.dispatch('eKyc/fetch_user_default_identities')
            }

            this.kycVerifyModalHidden()
            this.$bvToast.toast(response.data.message, {
              title: this.$t('messages.success.title'),
              variant: 'success',
              solid: true,
            })
          })
        } catch (error) {
          this.$bvToast.toast(error.response.data.message, {
            title: this.$t('global.error.title'),
            variant: 'danger',
            solid: true,
          })
        }
      },
      receiveMessage(event) {
        const eventDataCheck = event?.data

        if (eventDataCheck) {
          try {
            const eventData = typeof eventDataCheck === 'string' ? JSON.parse(eventDataCheck) : eventDataCheck
            if (eventData?.payload?.value == 'success' && this.transactionId) {
              this.updateStatus()
            }
          } catch (error) {}
        }
      },
      kycVerifyModalHidden() {
        this.showTips = true
        this.showDestinationAddress = false
        this.modalSrc = ''
        this.transactionId = null

        const privacyInfo = {
          kyc_type: 'no_agreement',
          providerPolicy: this.$t('scenes.ekyc.tips.provider-policy'),
          providerPolicyUrl: this.$t('url.identity.authentication.provider-privacy-policy'),
        }

        this.$store.commit('eKyc/SET_VERIFY_MODAL_DATA', {
          isShow: false,
          privacyInfo,
          package_id: null,
          isOneTime: false,
          isDestinationAddressRequired: false,
        })
      },
      checkDestinationAddressOrSubmitKyc() {
        if (this.privacyInfo && this.privacyInfo.kyc_type === 'agreement_checkbox' && !this.privacyInfo.checkbox) {
          const title = this.$t('global.error.title')
          const body = this.$t('messages.error.kyc-tips-agreement-not-checked')

          this.$bvToast.toast(body, { title, variant: 'danger', solid: true })
        } else if (this.kycVerifyModalData.isDestinationAddressRequired) {
          this.showDestinationAddress = true
        } else {
          this.showTips = false
        }
      },
      submitKyc(destinationAddress) {
        this.destinationAddress = destinationAddress
        this.showTips = false
      },
    },
    watch: {
      showTips(val) {
        if (val) {
          this.modalSrc = ''
          this.transactionId = null
        } else {
          this.requestJumio()
        }
      },
    },
    beforeDestroy() {
      window.removeEventListener('message', this.receiveMessage)
    },
    mounted() {
      window.addEventListener('message', this.receiveMessage, false)
    },
  }
</script>

<style lang="scss">
  .kycVerifyModal-dialog .modal-header .close {
    [dir='rtl'] & {
      margin: -1rem auto -1rem -1rem;
    }
  }

  .kycVerifyModal-dialog {
    &.modal-md {
      max-width: 540px;
    }

    .modal-content {
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
    }
  }

  .kycVerifyModal-header {
    .package-kycVerifyModal-title {
      color: #4a4a4a;
      font-size: 21px;
      font-weight: 600;
      letter-spacing: -0.3px;
      line-height: 25px;
      margin: 24px 0 2px 4px;
    }

    button.close {
      padding: 0;
      opacity: 1;

      img {
        margin-top: 9px;
        margin-right: 9px;

        [dir='rtl'] & {
          margin-right: unset;
          margin-left: 9px;
        }
      }
    }
  }

  .kycVerifyModal-body {
    padding: 20px 20px 40px 20px;

    .kycVerifyModal-content {
      .tips-description {
        color: #8a8a8a;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 21px;

        ul > li:not(:last-child) {
          margin-bottom: 20px;
        }
      }

      .kycVerifyModal-continueBtn {
        color: #ffffff;
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 11px;
        text-align: center;
        padding: 16px 20px 15px;
      }
    }

    .embed-responsive {
      min-height: 70vh;
    }
  }
</style>
